import { combineReducers } from 'redux';
import { IntlReducer as Intl } from 'react-redux-multilingual'

// Import custom components
import blogReducer from './blog';
import apartmentReducer from './apartment';
import basketReducer from "./basket";


const rootReducer = combineReducers({
    basket: basketReducer,
    news: blogReducer,
    apartments: apartmentReducer,
    Intl
});

export default rootReducer;