import React, { useState } from 'react';
import {Button} from "reactstrap"
import {removeAProduct} from "../../../actions";

import {ModelTypeToName} from "../../../constants/ProjectStores";

function DisplayProducts(props) {


    const _getTotal = () => {
        var total = 0.0
        for( var val in props.products){
            let price = props.products[val].price
            total += parseFloat( price.substring(1) )
        }
        return total


    }

    return(
        <div>
             <div className="block-table table-responsive">
                <table className="table">
                    <thead>
                    <tr className="property-variation-item text-uppercase">

                            <th key={"modeltype"}>{"Model Type"}</th>
                            <th key={"daystocomplete"}>{"Days to Complete"}</th>
                            <th key={"price"}>{"Price"}</th>
                            <th key={"remove"}>{"Remove"}</th>

                    </tr>
                    </thead>
                    <tbody>
                            {props.products.map((item, index) =>
                            <tr className="property-variation-item" key={index}>


                            <td key={'name' + index}>{ModelTypeToName[item.modelTypeIdx]}</td>
                            <td key={"days1"+ index}>{item.numberofDays}</td>
                            <td key={"price1"+ index}>{item.price}</td>
                            <td key={"remove"+ index}><Button onClick={()=> props.removeAProduct(index)} color="danger">X</Button></td>

                            </tr>
                            )}
                    </tbody>
                        <thead>
                            <tr className="property-variation-item text-uppercase">

                                <th key={"modeltype"}>{""}</th>
                                <th key={"daystocomplete"}>{""}</th>
                                <th key={"price"}>£{_getTotal() }</th>
                                <th key={"remove"}>{""}</th>

                                </tr>
                        </thead>

                </table>
             </div>
        </div>
        )



}


export default DisplayProducts;