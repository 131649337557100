import React, { Component } from 'react';
import CircularSlider from "../../common/custom-slider/CircularSlider";
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux';
import { addAProductToBasket, removeAProduct } from "../../../actions";
import { Link } from "react-router-dom";
import DisplayProducts from "../../common/basket/displayProducts";
import ExploreAmenitiesSection from "../../sections/explore-amenities/explore-amenities";

import CheckoutButton from "../../common/basket/CheckoutButton";

class Basket extends Component {
    state = {
        open: false,
        isLoading: true,
    }


    constructor(props) {
        super(props);

    }


    componentDidMount() {

        console.log("items", this.props.items)
    }

    removeAProductIdx(idx) {

        this.props.removeAProduct(idx)

    }


    render() {


        return (
            <div>
                <Helmet>
                    <title>3D MODELS AGENCY | BASKET</title>
                </Helmet>


                <div className="section-wrapper pb-0">
                    <ExploreAmenitiesSection />
                </div>

                <div className="section-wrapper pb-0">
                    <DisplayProducts products={this.props.items}
                        removeAProduct={(idx) => this.removeAProductIdx(idx)}
                    />
                </div>

                <div className="section-wrapper pb-0">
                    <CheckoutButton products={this.props.items} total={this.props.total} />

                </div>



            </div>
        )
    }
}



const AppMapStateToProps = state => {

    console.log("stat", state)
    return {
        items: state.basket.items,
        total: state.basket.items.reduce((a, c) => a + (Number(c.price.substring(1)) * c.modelCount), 0)
    };

}

const mapDispatchToProps = dispatch => {
    return {
        addAProductToBasket: (data) => {
            dispatch(addAProductToBasket(data));
        },
        removeAProduct: (idx) => {
            dispatch(removeAProduct(idx))
        }

    };
};

export default connect(AppMapStateToProps, mapDispatchToProps)(Basket)

