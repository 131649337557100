import React, {Component} from 'react';

import Slider from '@material-ui/core/Slider';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import { withStyles, makeStyles } from '@material-ui/core/styles';



import "./styles/styles.scss"

function valuetext(value) {
    return `${value}°C`;
}


const PrettoSlider = withStyles({
    root: {
        color: '#948352',
        height: 8,
    },
    thumb: {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        marginTop: -8,
        marginLeft: -12,
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
    },
    track: {
        height: 8,
        borderRadius: 4,
    },
    rail: {
        height: 8,
        borderRadius: 4,
    },
})(Slider);



function CountSlider(props) {

    const [value, setValue] = React.useState(1);

    const handleSliderChange = (event, newValue) => {
        setValue(newValue);
        props._updateCount(newValue)
    };

    const handleInputChange = (event) => {
        setValue(event.target.value === '' ? '' : Number(event.target.value));
        props._updateCount(Number(event.target.value))
    };

    const handleBlur = () => {
        if (value < 0) {
            setValue(1);
            props._updateCount(1)
        } else if (value > 100) {
            setValue(100);
            props._updateCount(100)
        }
    };


    return (


    <div className={"root-vertical-slider"}>
         <Grid container spacing={2} alignItems="center">
            <Grid item>
                <p>Count</p>
            </Grid>

            <Grid item xs>
            <PrettoSlider
            value={typeof value === 'number' ? value : 1}
            min={1}
            onChange={handleSliderChange}
            aria-labelledby="input-slider"
                />
                </Grid>
                <Grid item>
            <Input
            className={"count-input"}
            value={value}
            margin="dense"
            onChange={handleInputChange}
            onBlur={handleBlur}
            inputProps={{
                step: 1,
                    min: 1,
                    max: 100,
                    type: 'number',
                    'aria-labelledby': 'input-slider',
            }}
            />
            </Grid>
            </Grid>

    </div>

);
}



export default (CountSlider)

