import React, {Component} from 'react';
import {Helmet} from 'react-helmet'
// Import custom components

import ApartmentsPlansSection from "../../sections/ourvalues/ourvalues";


class OrderNow extends Component {
    render() {
        return (
            <div className={`home-page-wrap`}>
    <Helmet>
        <title>MaisonCo | Single Property</title>
        </Helmet>




            <div className="section-wrapper">
            <ApartmentsPlansSection history={this.props.history}/>
            </div>






        </div>
    )

    }
}

export default OrderNow;