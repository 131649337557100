import React, {Component} from 'react';
import Slider from "react-slick";
import './slider.scss';
class SliderHome extends Component {
    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 800,
            slidesToShow: 1,
            slidesToScroll: 1,
        };

        return (
            <div className={'slider-wrap position-relative'}>
                <Slider {...settings}>

                    <div className="slider-item position-relative">
                        <div className="slider-image" style={{backgroundImage: 'url(/assets/images/slider/3.jpg)'}}>
                        </div>
                        <div className="container">
                            <div className="slider-content">
                                <div className="text-center w-100">
                                    <h2 className="text-white">Made to Order <br/> Quick and Customizable </h2>
                                    <h5 className="text-white mt-5">Get in touch <br/>
                                        or order today</h5>
                                </div>
                                <div className="slider-footer text-left">
                                    <h6 className="text-white">3 Grades of Models</h6>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="slider-item position-relative">
                        <div className="slider-image" style={{backgroundImage: 'url(/assets/images/slider/1.jpg)'}}>
                        </div>
                        <div className="container">
                            <div className="slider-content">


                            </div>
                        </div>
                    </div>


                </Slider>
            </div>
        )
    }
}

export default SliderHome