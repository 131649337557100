import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "./ourvalues.scss";
import Heading from "../../common/heading/heading";
import Calculator from "../calculator/Calculator";

class ApartmentsPlansSection extends Component {
  state = {
    open: false,
    isLoading: true,
  };
  data = [];

  constructor(props) {
    super(props);
    this.load();
  }

  onCloseModal = () => {
    this.setState({ open: false });
  };

  load = () => {
    fetch("/api/our-values.json")
      .then((response) => response.json())
      .then((res) => {
        this.data = res;
        this.setState({ isLoading: false });
      });
  };

  render() {
    return (
      <div className="container">
        <Heading>Commission a Model</Heading>
        <div className={`tabs-wrap`}>
          {!this.state.isLoading && (
            <Tabs>
              <TabList>
                {this.data.map((data, index) => (
                  <Tab key={index}>{data.title}</Tab>
                ))}
              </TabList>
              {this.data.map((data, index) => (
                <TabPanel key={index}>
                  <div className="row">
                    <div className="col-md-6">
                      <p>{data.description}</p>
                      <ul className="apartment-items">
                        {data.attributes.map((attr, i) => (
                          <li className="apartment-list-item" key={i}>
                            <span className="title-list text-uppercase">
                              {attr.name}
                            </span>
                            <span className="value-list">{attr.option}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="col-md-6">
                      <div className="apartment-img">
                        <Calculator
                          index={index}
                          history={this.props.history}
                        />
                      </div>
                    </div>
                  </div>
                </TabPanel>
              ))}
            </Tabs>
          )}
        </div>
      </div>
    );
  }
}

export default ApartmentsPlansSection;
