import {ADD_PRODUCT, REMOVE_PRODUCT, REMOVE_ALL_PRODUCTS} from "../constants/ActionTypes";

const basket = {
    items: [],
    test:["sdssd"],
    sa:"as",
};

function remove(array, idx) {
            if (array.length == 1){ return []}
            var newArray = []

            for (var i in array){

                if(i != idx){newArray.push(array[i])}



            }

        return newArray

}

const basketReducer = (state = basket, action) => {

    console.log("adding to reducer", action.type)
    switch (action.type) {
        case ADD_PRODUCT:

            console.log("addinng to basket", action.product)



            if(action.product.modelCount == 1){

                return { ...state,
                    items: state.items.concat(action.product)};
            }
            else{

                let localStateitems = state.items
                console.log("localStateitems", localStateitems)

                for (var i = 0; i < (action.product.modelCount); i++) {
                    localStateitems = localStateitems.concat(action.product)
                    console.log("adding"+ i, localStateitems)
                }

                console.log("ending", localStateitems)
                return { ...state,
                    items: localStateitems};

            }



        case REMOVE_PRODUCT:
            var newState = Object.assign(state)
            let items = remove(newState.items, action.idx)
            console.log("Removing to basket", items.length,  action.idx, state.items)
            return { ...newState,
                    items: items };

        case REMOVE_ALL_PRODUCTS:

            return { ...state,
                     items: [] };


        default:
            return state;
    }
};
export default basketReducer;