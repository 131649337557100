import React, {Component} from 'react';
import Breadcrumb from "../../common/breadcrumb/breadcrumb";
import Helmet from "react-helmet";
import './the-building.scss';
import CounterBlockStyle from "../../common/countup/countup-style";
import GallerrySection from "../../sections/gallerry/gallerry";
class TheBuildingPage extends Component {
    render (){

        return (
            <div className={`the-building-wrap`}>
                <Helmet>
                    <title>Our Work</title>
                </Helmet>
                <Breadcrumb title={'Our Work'}/>
                <div className="section-wrapper">
                    <div className="container">
                        <div className="text-description text-center pt-5">
                            <h3 className="text-primary">You Choose</h3>
                            <h2>The Quality | Speed | Complexity</h2>
                            <h3>All Made to order</h3>
                            <p className="text-black-50 mt-5"><strong> We have three model options to choose from and you can then choose your timeframe. </strong></p>
                            <p className="mt-4">After you have paid, you can send over detailed instructions for model creation. Including documents, images and plans. </p>
                        </div>
                    </div>
                </div>


                <div className="section-wrapper">
                    <div className="gallerry-wrap">
                        <GallerrySection flexRow={true}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default TheBuildingPage