// Get Blog
export const FETCH_BLOG_BEGIN = 'FETCH_BLOG_BEGIN'
export const RECEIVE_BLOG = 'RECEIVE_BLOG'
export const FETCH_SINGLE_BLOG = 'FETCH_SINGLE_BLOG'
export  const ADD_PRODUCT = "ADD_PRODUCT"

export  const REMOVE_PRODUCT = "REMOVE_PRODUCT"
export  const REMOVE_ALL_PRODUCTS = 'REMOVE_ALL_PRODUCTS'


