import React, { Component } from "react";
import { withTranslate } from "react-redux-multilingual";
import "./footer.scss";
import Copyright from "../copyright/copyright";
import ContactForm from "../contact-form/contact-form";
import Modal from "react-responsive-modal";

class FooterOne extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openReturns: false,
      openTerms: false,
    };
  }

  onOpenTermsModal = (e) => {
    e.preventDefault();
    this.setState({ openTerms: true });
  };

  onCloseTermsModal = () => {
    this.setState({ openTerms: false });
  };

  onOpenReturnsModal = (e) => {
    e.preventDefault();
    this.setState({ openReturns: true });
  };

  onCloseReturnsModal = () => {
    this.setState({ openReturns: false });
  };

  render() {
    const { translate } = this.props;
    return (
      <footer className="site-footer">
        {/*<ShapeSVG position={`top`}/>*/}
        {/*Section #1*/}
        <div className="section-1 text-center">
          <div className="container">
            <h3 className="text-uppercase">Get Started </h3>
            <h2 className="text-uppercase">Today</h2>
            <h3 className="text-uppercase">Get in touch with us</h3>
          </div>
        </div>

        {/*Section 2*/}
        <div className="section-2">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-12">
                <div className="icon-list footer-address">
                  <ul>
                    <li>
                      <span className="icon-list-icon">ADDRESS:</span>
                      <span className="icon-list-text">
                        447 Broadway, #509 2nd floor, New York NY 10013
                      </span>
                    </li>

                    <li>
                      <span className="icon-list-icon">E-MAIL:</span>
                      <span className="icon-list-text">
                        hello@3dmodels.agency
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 col-md-12"></div>
              <div className="col-lg-3 col-md-12">
                <h6 className="heading-title">CONTACT US</h6>

                <div className="icon-list">
                  <ul>
                    <li>
                      <span className="icon-list-icon">
                        <i className="fa fa-phone"></i>
                      </span>

                      <span className="icon-list-text">
                        SKYPE:ID: hello@3dmodels.agency
                      </span>
                    </li>
                    <li>
                      <span className="icon-list-icon">
                        <i className="fa fa-envelope"></i>
                      </span>
                      <span className="icon-list-text">
                        hello@3dmodels.agency
                      </span>
                    </li>
                    <li>
                      <span className="icon-list-icon">
                        <i className="fa fa-file-text"></i>
                      </span>
                      <span
                        onClick={() => this.setState({ openTerms: true })}
                        className="icon-list-text"
                      >
                        Terms
                      </span>
                    </li>
                    <li>
                      <span className="icon-list-icon">
                        <i className="fa fa-compress"></i>
                      </span>
                      <span
                        onClick={() => this.setState({ openReturns: true })}
                        className="icon-list-text"
                      >
                        Returns
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-1 col-md-12"></div>
            </div>
          </div>
        </div>
        {/*Section #2*/}
        {/*Section 3*/}
        <div className="section-3">
          <div className="container text-center">
            <div className="row">
              <div className="col-sm-12">
                <Copyright title="3D MODELS Agency" />
              </div>
            </div>
          </div>
        </div>

        <Modal
          open={this.state.openTerms}
          onClose={this.onCloseTermsModal}
          center
        >
          <div
            className="modal-dialog modal-lg modal-dialog-centered"
            role="document"
          >
            <div className="modal-content quick-view-modal">
              <div className="modal-body">
                <h6 className="heading-title">
                  Interpretation and Definitions
                </h6>

                <p>
                  Please read these terms and conditions carefully before using
                  Our Service.
                </p>

                <p>Interpretation and Definitions</p>

                <p>
                  Interpretation The words of which the initial letter is
                  capitalized have meanings defined under the following
                  conditions. The following definitions shall have the same
                  meaning regardless of whether they appear in singular or in
                  plural.
                </p>

                <p>
                  Definitions For the purposes of these Terms and Conditions:
                </p>

                <p>
                  {" "}
                  Affiliate means an entity that controls, is controlled by or
                  is under common control with a party, where
                  &quot;control&quot; means ownership of 50% or more of the
                  shares, equity interest or other securities entitled to vote
                  for election of directors or other managing authority.
                </p>

                <p> Country refers to: United Kingdom</p>

                <p>
                  {" "}
                  Company (referred to as either &quot;the Company&quot;,
                  &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this
                  Agreement) refers to 3D Model Agency, 104 College Road Harrow
                  HA1 1BQ England .
                </p>

                <p>
                  {" "}
                  Device means any device that can access the Service such as a
                  computer, a cellphone or a digital tablet.
                </p>

                <p> Service refers to the Website.</p>

                <p>
                  {" "}
                  Terms and Conditions (also referred as &quot;Terms&quot;) mean
                  these Terms and Conditions that form the entire agreement
                  between You and the Company regarding the use of the Service.
                  This Terms and Conditions agreement has been created with the
                  help of the Terms and Conditions Generator.
                </p>

                <p>
                  {" "}
                  Third-party Social Media Service means any services or content
                  (including data, information, products or services) provided
                  by a third-party that may be displayed, included or made
                  available by the Service.
                </p>

                <p>
                  {" "}
                  Website refers to 3D Model Agency, accessible from
                  Www.3dmodels.agency
                </p>

                <p>
                  {" "}
                  You means the individual accessing or using the Service, or
                  the company, or other legal entity on behalf of which such
                  individual is accessing or using the Service, as applicable.
                </p>

                <p>
                  Acknowledgment These are the Terms and Conditions governing
                  the use of this Service and the agreement that operates
                  between You and the Company. These Terms and Conditions set
                  out the rights and obligations of all users regarding the use
                  of the Service. Your access to and use of the Service is
                  conditioned on Your acceptance of and compliance with these
                  Terms and Conditions. These Terms and Conditions apply to all
                  visitors, users and others who access or use the Service. By
                  accessing or using the Service You agree to be bound by these
                  Terms and Conditions. If You disagree with any part of these
                  Terms and Conditions then You may not access the Service. You
                  represent that you are over the age of 18. The Company does
                  not permit those under 18 to use the Service. Your access to
                  and use of the Service is also conditioned on Your acceptance
                  of and compliance with the Privacy Policy of the Company. Our
                  Privacy Policy describes Our policies and procedures on the
                  collection, use and disclosure of Your personal information
                  when You use the Application or the Website and tells You
                  about Your privacy rights and how the law protects You. Please
                  read Our Privacy Policy carefully before using Our Service.
                </p>

                <p>
                  Links to Other Websites Our Service may contain links to
                  third-party web sites or services that are not owned or
                  controlled by the Company. The Company has no control over,
                  and assumes no responsibility for, the content, privacy
                  policies, or practices of any third party web sites or
                  services. You further acknowledge and agree that the Company
                  shall not be responsible or liable, directly or indirectly,
                  for any damage or loss caused or alleged to be caused by or in
                  connection with the use of or reliance on any such content,
                  goods or services available on or through any such web sites
                  or services. We strongly advise You to read the terms and
                  conditions and privacy policies of any third-party web sites
                  or services that You visit.
                </p>

                <p>
                  Termination We may terminate or suspend Your access
                  immediately, without prior notice or liability, for any reason
                  whatsoever, including without limitation if You breach these
                  Terms and Conditions. Upon termination, Your right to use the
                  Service will cease immediately.
                </p>

                <p>
                  Limitation of Liability Notwithstanding any damages that You
                  might incur, the entire liability of the Company and any of
                  its suppliers under any provision of this Terms and Your
                  exclusive remedy for all of the foregoing shall be limited to
                  the amount actually paid by You through the Service or 100 USD
                  if You haven't purchased anything through the Service. To the
                  maximum extent permitted by applicable law, in no event shall
                  the Company or its suppliers be liable for any special,
                  incidental, indirect, or consequential damages whatsoever
                  (including, but not limited to, damages for loss of profits,
                  loss of data or other information, for business interruption,
                  for personal injury, loss of privacy arising out of or in any
                  way related to the use of or inability to use the Service,
                  third-party software and/or third-party hardware used with the
                  Service, or otherwise in connection with any provision of this
                  Terms), even if the Company or any supplier has been advised
                  of the possibility of such damages and even if the remedy
                  fails of its essential purpose. Some states do not allow the
                  exclusion of implied warranties or limitation of liability for
                  incidental or consequential damages, which means that some of
                  the above limitations may not apply. In these states, each
                  party's liability will be limited to the greatest extent
                  permitted by law.
                </p>

                <p>
                  &quot;AS IS&quot; and &quot;AS AVAILABLE&quot; Disclaimer The
                  Service is provided to You &quot;AS IS&quot; and &quot;AS
                  AVAILABLE&quot; and with all faults and defects without
                  warranty of any kind. To the maximum extent permitted under
                  applicable law, the Company, on its own behalf and on behalf
                  of its Affiliates and its and their respective licensors and
                  service providers, expressly disclaims all warranties, whether
                  express, implied, statutory or otherwise, with respect to the
                  Service, including all implied warranties of merchantability,
                  fitness for a particular purpose, title and non-infringement,
                  and warranties that may arise out of course of dealing, course
                  of performance, usage or trade practice. Without limitation to
                  the foregoing, the Company provides no warranty or
                  undertaking, and makes no representation of any kind that the
                  Service will meet Your requirements, achieve any intended
                  results, be compatible or work with any other software,
                  applications, systems or services, operate without
                  interruption, meet any performance or reliability standards or
                  be error free or that any errors or defects can or will be
                  corrected. Without limiting the foregoing, neither the Company
                  nor any of the company's provider makes any representation or
                  warranty of any kind, express or implied: (i) as to the
                  operation or availability of the Service, or the information,
                  content, and materials or products included thereon; (ii) that
                  the Service will be uninterrupted or error-free; (iii) as to
                  the accuracy, reliability, or currency of any information or
                  content provided through the Service; or (iv) that the
                  Service, its servers, the content, or e-mails sent from or on
                  behalf of the Company are free of viruses, scripts, trojan
                  horses, worms, malware, timebombs or other harmful components.
                  Some jurisdictions do not allow the exclusion of certain types
                  of warranties or limitations on applicable statutory rights of
                  a consumer, so some or all of the above exclusions and
                  limitations may not apply to You. But in such a case the
                  exclusions and limitations set forth in this section shall be
                  applied to the greatest extent enforceable under applicable
                  law.
                </p>

                <p>
                  Governing Law The laws of the Country, excluding its conflicts
                  of law rules, shall govern this Terms and Your use of the
                  Service. Your use of the Application may also be subject to
                  other local, state, national, or international laws.
                </p>

                <p>
                  Disputes Resolution If You have any concern or dispute about
                  the Service, You agree to first try to resolve the dispute
                  informally by contacting the Company.
                </p>

                <p>
                  For European Union (EU) Users If You are a European Union
                  consumer, you will benefit from any mandatory provisions of
                  the law of the country in which you are resident in.
                </p>

                <p>
                  United States Legal Compliance You represent and warrant that
                  (i) You are not located in a country that is subject to the
                  United States government embargo, or that has been designated
                  by the United States government as a &quot;terrorist
                  supporting&quot; country, and (ii) You are not listed on any
                  United States government list of prohibited or restricted
                  parties.
                </p>

                <p>Severability and Waiver</p>

                <p>
                  Severability If any provision of these Terms is held to be
                  unenforceable or invalid, such provision will be changed and
                  interpreted to accomplish the objectives of such provision to
                  the greatest extent possible under applicable law and the
                  remaining provisions will continue in full force and effect.
                </p>

                <p>
                  Waiver Except as provided herein, the failure to exercise a
                  right or to require performance of an obligation under this
                  Terms shall not effect a party's ability to exercise such
                  right or require such performance at any time thereafter nor
                  shall be the waiver of a breach constitute a waiver of any
                  subsequent breach.
                </p>

                <p>
                  Translation Interpretation These Terms and Conditions may have
                  been translated if We have made them available to You on our
                  Service. You agree that the original English text shall
                  prevail in the case of a dispute.
                </p>

                <p>
                  Changes to These Terms and Conditions We reserve the right, at
                  Our sole discretion, to modify or replace these Terms at any
                  time. If a revision is material We will make reasonable
                  efforts to provide at least 30 days' notice prior to any new
                  terms taking effect. What constitutes a material change will
                  be determined at Our sole discretion. By continuing to access
                  or use Our Service after those revisions become effective, You
                  agree to be bound by the revised terms. If You do not agree to
                  the new terms, in whole or in part, please stop using the
                  website and the Service.
                </p>

                <p>
                  Contact Us If you have any questions about these Terms and
                  Conditions, You can contact us:
                </p>

                <p>By email: Hello@3dmodels.agency</p>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          open={this.state.openReturns}
          onClose={this.onCloseReturnsModal}
          center
        >
          <div
            className="modal-dialog modal-lg modal-dialog-centered"
            role="document"
          >
            <div className="modal-content quick-view-modal">
              <div className="modal-body">
                <h6 className="heading-title">Returns Policy</h6>
                <p>
                  Thank you for shopping at 3D Model Agency. If, for any reason,
                  You are not completely satisfied with a purchase We invite You
                  to review our policy on refunds and returns. This Return and
                  Refund Policy has been created with the help of the Return and
                  Refund Policy Generator. The following terms are applicable
                  for any products that You purchased with Us.
                </p>

                <p>Interpretation and Definitions</p>

                <p>
                  Interpretation The words of which the initial letter is
                  capitalized have meanings defined under the following
                  conditions. The following definitions shall have the same
                  meaning regardless of whether they appear in singular or in
                  plural.
                </p>

                <p>
                  Definitions For the purposes of this Return and Refund Policy:
                </p>

                <p>
                  {" "}
                  Company (referred to as either &quot;the Company&quot;,
                  &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this
                  Agreement) refers to 3D Model Agency Limited, 104 College Road
                  Harrow HA1 1BQ.
                </p>

                <p>
                  {" "}
                  Goods refer to the items offered for sale on the Service.
                </p>

                <p> Orders mean a request by You to purchase Goods from Us.</p>

                <p> Service refers to the Website.</p>

                <p>
                  {" "}
                  Website refers to 3D Model Agency, accessible from
                  www.3dmodels.agency
                </p>

                <p>
                  {" "}
                  You means the individual accessing or using the Service, or
                  the company, or other legal entity on behalf of which such
                  individual is accessing or using the Service, as applicable.
                </p>

                <p>
                  Your Order Cancellation Rights You are entitled to cancel Your
                  Order within 7 days without giving any reason for doing so.
                  The deadline for cancelling an Order is 7 days from the date
                  on which You received the Goods or on which a third party you
                  have appointed, who is not the carrier, takes possession of
                  the product delivered. In order to exercise Your right of
                  cancellation, You must inform Us of your decision by means of
                  a clear statement. You can inform us of your decision by:
                </p>

                <p>By email: hello@3dmodels.agency</p>

                <p>
                  We will reimburse You no later than 14 days from the day on
                  which We receive the returned Goods. We will use the same
                  means of payment as You used for the Order, and You will not
                  incur any fees for such reimbursement.
                </p>

                <p>
                  Conditions for Returns In order for the Goods to be eligible
                  for a return, please make sure that:
                </p>

                <p>
                  The Goods were purchased in the last 7 days The Goods are in
                  the original packaging
                </p>

                <p>The following Goods cannot be returned:</p>

                <p>
                  The supply of Goods made to Your specifications or clearly
                  personalized. The supply of Goods which according to their
                  nature are not suitable to be returned, deteriorate rapidly or
                  where the date of expiry is over. The supply of Goods which
                  are not suitable for return due to health protection or
                  hygiene reasons and were unsealed after delivery. The supply
                  of Goods which are, after delivery, according to their nature,
                  inseparably mixed with other items.
                </p>

                <p>
                  We reserve the right to refuse returns of any merchandise that
                  does not meet the above return conditions in our sole
                  discretion. Only regular priced Goods may be refunded.
                  Unfortunately, Goods on sale cannot be refunded. This
                  exclusion may not apply to You if it is not permitted by
                  applicable law.
                </p>

                <p>
                  Returning Goods You are responsible for the cost and risk of
                  returning the Goods to Us. You should send the Goods at the
                  following address: 104 College Road Harrow HA1 1BQ We cannot
                  be held responsible for Goods damaged or lost in return
                  shipment. Therefore, We recommend an insured and trackable
                  mail service. We are unable to issue a refund without actual
                  receipt of the Goods or proof of received return delivery.
                </p>

                <p>
                  Gifts If the Goods were marked as a gift when purchased and
                  then shipped directly to you, You'll receive a gift credit for
                  the value of your return. Once the returned product is
                  received, a gift certificate will be mailed to You. If the
                  Goods weren't marked as a gift when purchased, or the gift
                  giver had the Order shipped to themselves to give it to You
                  later, We will send the refund to the gift giver.
                </p>

                <p>
                  Contact Us If you have any questions about our Returns and
                  Refunds Policy, please contact us:
                </p>

                <p>By email: hello@3dmodels.agency</p>
              </div>
            </div>
          </div>
        </Modal>
      </footer>
    );
  }
}

export default withTranslate(FooterOne);
