import React, { useState, useEffect, } from 'react';
import { Link ,useLocation } from 'react-router-dom';
import {removeAllProducts} from "../../../actions";
import {Input} from 'reactstrap'
import "./sucessStyles.scss"
import {connect} from "react-redux";
import {ModelTypeToName} from "../../../constants/ProjectStores";
import {addAProductToBasket, removeAProduct} from "../../../actions";
import SaveButton from "../../common/basket/saveButton";
import {Helmet} from 'react-helmet'

const checkPaymentStatus = async (sessionID) => {
    return  fetch("https://modelsagencyapi.herokuapp.com/api/checkAPaymentStatus",  {
        method: 'post',
        headers: {'Content-Type':'application/json'},
        body: JSON.stringify({
            "sessionID": sessionID
        }),
    }).then((res) => res.json()).then(
        (result) => {
            console.log("got  result", result)
            return( result)
        },
        (error) => {
            console.log("no  result", error)
        }
    )
};

const saveItems = async (sessionID, orders ) => {

    return  fetch("https://modelsagencyapi.herokuapp.com/api/updateAPaymentWithDesc",  {
        method: 'post',
        headers: {'Content-Type':'application/json'},
        body: JSON.stringify({
            "sessionID": sessionID,
            "orders":orders
        }),
    }).then((res) => res.json()).then(
        (result) => {
            console.log("got  result", result)
            return( result)
        },
        (error) => {
            console.log("no  result", error)
        }
    )



}





const Success = (props) => {
    const [session, setSession] = useState({});
    const [descriptionObj, setDescriptionObj] = useState({});



    const location = useLocation();
    const sessionId = location.search.replace('?session_id=', '');

    useEffect(() => {


        async function fetchSession() {
            setSession(
                await fetch('/checkout-session?sessionId=' + sessionId).then((res) =>
                    res.json()
                )
            );



        }
        console.log("checking----->", sessionId )
        checkPaymentStatus(sessionId)

        fetchSession();
    }, [sessionId]);

    useEffect(() =>{


        console.log('sessioon here =====> '  +  sessionId)
    },[])

   const  handleChange = (e, index) => {

        var newDescriptionObj = Object.assign(descriptionObj)
        newDescriptionObj[index] =  e.target.value
        setDescriptionObj(newDescriptionObj)

    }


    const didSave = () => {
        var newData = []

        for(var item  in props.items){
            var newItem = props.items[item]
            newItem["description"] = descriptionObj[item]
            newData.push(newItem)
        }

        saveItems(sessionId,newData)



        props.removeAllProducts()
        props.history.push("/")


    }

    return (
        <div className="sr-root">
                <Helmet>
                    <title>3D MODELS AGENCY | SUCESS</title>
                </Helmet>


            <div className="sr-main">
                <header className="sr-header">
                    <div className="sr-header__logo"></div>
                 </header>
                <div className="sr-payment-summary completed-view">
                        <h1>Your payment succeeded</h1>
                    <h4></h4>
                </div>

                <div>

                    {props.items.map((val, index)=> {

                        return(

                            <div className="block-table table-responsive">
                                <table className="table">

                                <thead>
                                <tr className="property-variation-item text-uppercase">

                                <th key={"model" + index}>{"Model"}</th>
                                <th key={"Description"+ index}>{"Tell us what you need. Include links in desc"}</th>


                                </tr>
                                </thead>

                                <th key={"modeltype" + index}>{ModelTypeToName[val.modelTypeIdx]} - days: {val.numberofDays}</th>
                                <th key={"input" + index}> <Input type="textarea" name="text" id="exampleText" onChange={(e) => handleChange(e,index)} /></th>
                                </table>
                            </div>

                        )


                        })}


                </div>

                <div>


                <div className="section-wrapper pb-0">
                    <SaveButton  didSave={() => didSave() }/>
                 </div>






                 </div>

        </div>




        </div>
);
};


const AppMapStateToProps = state => {

    return {
        items: state.basket.items,
    };

}

const mapDispatchToProps = dispatch => {
    return {
        removeAllProducts: () => {
            dispatch(removeAllProducts());
        },

    };
};


export default connect(AppMapStateToProps, mapDispatchToProps)(Success);
