import React, { useState, useEffect } from 'react';
import {Button} from "reactstrap"



const fetchCheckoutSession = async () => {
    return  fetch("https://modelsagencyapi.herokuapp.com/api/get_group_token",  {
        method: 'post',
        headers: {'Content-Type':'application/json'},
        body: JSON.stringify({
            "amount": 1000
        }),
    }).then((res) => res.json()).then(
        (result) => {
            console.log("got  result", result)
            return( result)
        },
        (error) => {
            console.log("no  result", error)
        }
    )
};




function SaveButton(props) {





    const onSubmit =  () =>{

            props.didSave()


    }


    return(
        <div  className="row amenities" >

                <div className="col-lg-6 col-md-6 col-sm-6 align-self-center text-center"/>




                <div className="col-lg-6 col-md-6 col-sm-6 align-self-center text-center">
                     <Button onClick={()=> onSubmit() } color="primary">Submit</Button>
                </div>

        </div>
)



}


export default SaveButton;