import React from "react";
import { Button } from "reactstrap";

// const fetchCheckoutSession = async (total) => {
//     return  fetch("https://modelsagencyapi.herokuapp.com/api/get_group_token",  {
//         method: 'post',
//         headers: {'Content-Type':'application/json'},
//         body: JSON.stringify({
//             "amount": total
//         }),
//     }).then((res) => res.json()).then(
//         (result) => {
//             console.log("got  result", result)
//             return( result)
//         },
//         (error) => {
//             console.log("no  result", error)
//         }
//     )
// };

function CheckoutButton(props) {
  //const [paymentRequest, setPaymentRequest] = useState(null);

  const _getTotal = () => {
    var total = 0.0;
    for (var val in props.products) {
      let price = props.products[val].price;
      total += parseFloat(price.substring(1));
    }

    console.log("totoal", total * 100);
    return total * 100;
  };
 // const checkout = async () => {
    // alert(`service error - please email order and contact phone number to: 
    // hello@3dmodels.agency`);

    // If `redirectToCheckout` fails due to a browser or network
    // error, display the localized error message to your customer
    // using `error.message`.
    // if (error) {
    //     console.log("error")
    // }

    /*
        fetch("https://modelsagencyapi.herokuapp.com/api/get_group_token",  {
            method: 'post',
            headers: {'Content-Type':'application/json'},
            body: JSON.stringify({
                "amount": 1000
            })
             })
            .then(res => res.json())
            .then(
                (result) => {
                   console.log("got  result", result)
                },
                (error) => {
                    console.log("no  result", error)
                }
            )

         */
 // };

  return (
    <div className="row amenities">
      <div className="col-lg-6 col-md-6 col-sm-6 align-self-center text-center"></div>

      <div className="col-lg-6 col-md-6 col-sm-6 align-self-center text-center">
        {/* <Button onClick={() => checkout()} color="primary">
          Checkout
        </Button> */}



        <form action="https://secure.worldpay.com/wcc/purchase" method={"POST"}>

          <input type="hidden" name="testMode" value="0" />
          <input type="hidden" name="instId" value="1423499" />
          <input type="hidden" name="cartId" value="123" />
          <input type="hidden" name="amount" value={props.total} />
          <input type="hidden" name="currency" value="GBP" />
          <input type={"submit"} color="primary" value="Buy This" />

        </form>
       <div style={{backgroundColor: 'white'}}>
       <img width="200px" alt ="worldpay credit card logos" src="/assets/images/worldpay-1.jpg" />
         </div> 
      </div>
    </div>

  );
}

export default CheckoutButton;
