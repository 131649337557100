import React from 'react';
import { Link } from 'react-router-dom';
import {Helmet} from 'react-helmet'


const Canceled = () => {
    return (

        <div className="sr-root">
                <Helmet>
                    <title>3D MODELS AGENCY | SUCESS</title>
                </Helmet>
        <div className="sr-main">
        <header className="sr-header">
        <div className="sr-header__logo"></div>
        </header>
        <div className="sr-payment-summary completed-view">
        <h1>Sorry your payment was declined</h1>
        <h4> Please try again </h4>
             <Link to="/">Home</Link>
             </div>
             </div>
         </div>
);
};

export default Canceled;
