import React, { Component } from "react";
import CircularSlider from "../../common/custom-slider/CircularSlider";
import "./calculatorStyles.scss";
import { connect } from "react-redux";
import { addAProductToBasket } from "../../../actions";
import VerticalSlider from "../../common/calculator/countSlider";
import { Link } from "react-router-dom";

const wakeUpServer = async () => {
  return fetch("https://modelsagencyapi.herokuapp.com/api/wakeUpHerokuServer", {
    method: "post",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      body: "body",
    }),
  })
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {}
    );
};

class Calculator extends Component {
  state = {
    open: false,
    isLoading: true,
    count: 1,
    numberOfDays: 5,
  };
  data = [];

  constructor(props) {
    super(props);

    this.load();

    this.onCalcChange = this.onCalcChange.bind(this);
    this.onPurchase = this.onPurchase.bind(this);
    this._updateCount = this._updateCount.bind(this);
  }

  componentDidMount() {
    wakeUpServer();
  }

  load = () => {
    fetch("/api/calculator.json")
      .then((response) => response.json())
      .then((res) => {
        this.data = this._makePriceArray(res[this.props.index].basePrice);

        this.setState({ isLoading: false });
      });
  };

  _makePriceArray(initialPrice) {
    var result = [];
    const maxDays = 9;

    for (var i = 0; i <= maxDays; i++) {
      let mutlplier = (initialPrice * (maxDays - i)) / maxDays;
      var val = mutlplier + initialPrice;
      val = "$" + (Math.round(val * 100) / 100).toFixed(2);
      result.push(val);
    }

    return result;
  }

  onCalcChange(val) {
    let numberOfDays = this.data.indexOf(val);
    this.setState({ numberOfDays: numberOfDays });
  }
  onPurchase() {
    let product = {
      price: this.data[this.state.numberOfDays],
      numberofDays: this.state.numberOfDays + 1,
      modelTypeIdx: this.props.index,
      modelCount: this.state.count,
    };

    this.props.addAProductToBasket(product);

    this.props.history.push("/basket");
  }

  _updateCount(val) {
    console.log("updating count in parent", val);
    this.setState({ count: val });
  }

  render() {
    return (
      <div className="outer-calculator">
        {!this.state.isLoading ? (
          <div className="outer-calculator">
            <div style={{ flex: 4 }} className="inner-calculator">
              <CircularSlider
                label="Model Cost"
                labelColor="#212121"
                knobColor="#000"
                progressColorFrom="#948352"
                progressColorTo="#eae5d9"
                progressSize={25}
                trackColor="#eeeeee"
                trackSize={25}
                data={this.data}
                dataIndex={0}
                onChange={(value) => {
                  this.onCalcChange(value);
                }}
              />

              <div className={"days-container"}>
                <p className={"day-count"}> {this.state.numberOfDays + 1} </p>
                <p>
                  {" "}
                  {this.state.numberOfDays > 1 ? "Days" : "Day"} Turnaround
                </p>
              </div>

              <div className={"days-container"}>
                <VerticalSlider
                  _updateCount={(count) => this._updateCount(count)}
                />
              </div>

              <div className="btn-call-to-action">
                <button
                  onClick={() => this.onPurchase()}
                  className="btn btn-dark"
                >
                  Purchase{" "}
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addAProductToBasket: (data) => {
      dispatch(addAProductToBasket(data));
    },
  };
};

export default connect(null, mapDispatchToProps)(Calculator);
