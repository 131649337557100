import React, { Component } from "react";
import Breadcrumb from "../../common/breadcrumb/breadcrumb";
import Helmet from "react-helmet";
import "./contact.scss";

class ContactPage extends Component {
  render() {
    return (
      <div className={`contact-page-wrap`}>
        <Helmet>
          <title> Contact Us </title>
        </Helmet>
        <Breadcrumb title={"Contact Us"} />
        <div className="section-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-4">
                <div className="box-icon">
                  <div className="icon">
                    <i className="opal-icon-phone"></i>
                  </div>
                  <div className="content">
                    <span className="title text-uppercase">PHONE :</span>
                    <p className="description">SKYPE:ID</p>
                    <p className="description">hello@3dmodels.agency</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4">
                <div className="box-icon">
                  <div className="icon">
                    <i className="opal-icon-point"></i>
                  </div>
                  <div className="content">
                    <span className="title text-uppercase">ADDRESS :</span>
                    <p className="description">447 Broadway</p>
                    <p className="description">#509 2nd floor</p>
                    <p className="description">New York NY 10013</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4">
                <div className="box-icon no-border">
                  <div className="icon">
                    <i className="opal-icon-envelope"></i>
                  </div>
                  <div className="content">
                    <span className="title text-uppercase">E-MAIL :</span>
                    <p className="description">hello@3dmodels.agency</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactPage;
