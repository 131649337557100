import React, { Component } from "react";
import Heading from "../../common/heading/heading";

class OverviewSection extends Component {
  render() {
    return (
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12 col-sm-12 content-left">
            <Heading>About US</Heading>
            <p className="text-black">
              THE TEAM AT 3DModels.agency ARE PASSIONATE ABOUT MODELING. WE
              PRODUCE 3D ASSETS AND MODELS FOR THE VFX, GAME AND ARCH VIZ
              INDUSTRIES
            </p>
            <p className="text-black">
              WE WORK AT A SPEED AND QUALITY THAT SUITS OUR CLIENT'S NEEDS WHEN
              COMMISSIONING A MODEL YOU CAN CHOOSE FROM THREE DIFFERENT DELIVERY
              SPEEDS AND THREE DIFFERENT QUALITY OPTIONS
            </p>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12">
            <img
              className="img-fluid"
              src="/assets/images/theme/img-051.jpg"
              alt=""
            />
          </div>
        </div>
      </div>
    );
  }
}

export default OverviewSection;
