import React, {Component} from 'react';
import './explore-amenities.scss';

class ExploreAmenitiesSection extends Component {
    render (){
        return (
            <div className="amenities-general amenities-bg">

                            <h3 className="text-uppercase text-primary">Checkout</h3>


            </div>
        )
    }
}

export default ExploreAmenitiesSection